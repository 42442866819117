import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from "gatsby-image"

import LayoutHome from 'components/LayoutHome';
import audio_sound_dries from 'assets/images/audio/sound-dries.mp3';
import captions_sound_dries from 'file-loader!assets/images/audio/sound-dries.vtt';

export const query = graphql`
query {
    img_cover_attest: file(relativePath: {eq: "png/gallery-cover-attest.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    img_cover_cc: file(relativePath: {eq: "jpg/gallery-cover-cc.jpg"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img_cover_sp: file(relativePath: {eq: "png/gallery-cover-sp.png"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img_cover_tw: file(relativePath: {eq: "png/gallery-cover-tw.png"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img_cover_scr: file(relativePath: {eq: "jpg/gallery-cover-scr.jpg"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img_cover_echelon: file(relativePath: {eq: "png/gallery-cover-echelon.png"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img_cover_bene: file(relativePath: {eq: "png/gallery-cover-bene.png"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    img_ferret_teaser: file(relativePath: {eq: "jpg/ferret-teaser.jpg"}) {
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img_cc_teaser: file(relativePath: {eq: "jpg/cc-teaser.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
    }
    img_sp_teaser: file(relativePath: {eq: "jpg/sp-teaser.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_ac_teaser: file(relativePath: {eq: "jpg/ac-teaser-1x2.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_bene_teaser: file(relativePath: {eq: "jpg/bene-teaser.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_werchter_teaser: file(relativePath: {eq: "jpg/werchter-teaser.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_matexi_teaser: file(relativePath: {eq: "jpg/matexi-teaser.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_maunga_teaser: file(relativePath: {eq: "jpg/maunga-teaser.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_echelon_teaser: file(relativePath: {eq: "jpg/echelon-teaser.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_about: file(relativePath: {eq: "jpg/driesstandaert-photo.jpg"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img_tshape: file(relativePath: {eq: "png/tshape.png"}) {
        childImageSharp {
          fluid {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`


const IndexPage = ({ data }) => {
    useEffect(() => {
        // Tooltip (not on mobile)
        if (window.matchMedia('(min-width:678px)').matches)
            document.querySelectorAll('.js-toolbox').forEach(el => {
                el.addEventListener('mousemove', event => {
                    const position = el.getBoundingClientRect();
                    const x = event.clientX - position.left - 70;
                    const y = event.clientY - position.top + 20;
                    el.getElementsByClassName('js-tooltip')[0].style.left = x + "px";
                    el.getElementsByClassName('js-tooltip')[0].style.top = y + "px";
                })
            })
    });
    return (
        <LayoutHome pageName="home">
            <Helmet>
                <title>Dries Standaert - Digital product designer</title>
                <meta name="description" content="Digital product designer that combines research and code to design simple, effective products."/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap" rel="stylesheet"/>
                <link href="https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Roboto:wght@400;500;700&display=swap" rel="stylesheet"/>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            
            <section className="c-row c-row--beta c-row-intro">
                <div className="f-grid u-reverse">
                    <div className="f-col-bp1-4 f-col-bp3-8 u-ver-center">
                    <div className="c-intro">
                        <h1 className="c-intro__title">
                            <span data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="400" data-aos-delay="500" data-aos-anchor="hero">
                            Hi, I'm <span className="c-sound js-sound-trigger">Dries</span>. I combine research and code to design effective products. </span>
                            <audio id="js-sound">
                                <source src={audio_sound_dries} />
                                <track src={captions_sound_dries} kind="captions" srcLang="en" label="english_captions"></track>
                            </audio>
                        </h1>
                    </div>
                    </div>
                    <div className="f-col-bp1-4 f-col-bp3-4">
                        <div className="c-intro__img">
                            <figure className="u-border-radius-16">
                                <Img fluid={data.img_about.childImageSharp.fluid} alt="Dries Standaert" />
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="c-row c-gallery u-mb-gamma" id="work" data-aos="fade-up" data-aos-anchor="hero" data-aos-delay="1000">
                <Link to="/attest/" className="c-gallery-item c-gallery-item--1 u-bgcolor-neutral-light">
                   
                    <Img fluid={data.img_cover_attest.childImageSharp.fluid} alt="Attest" className="c-gallery-item__img" />
                    <div className="c-gallery-meta">
                        <p className="c-gallery-meta__text"><span className="c-avatar"></span>Attest</p>
                        <ul className="o-list o-list--horizontal c-tags">
                            <li className="o-list__item c-tag">Research</li>
                            <li className="o-list__item c-tag">IA</li>
                            <li className="o-list__item c-tag">Prototyping</li>
                            <li className="o-list__item c-tag">Visual design</li>
                            <li className="o-list__item c-tag">Testing</li>
                        </ul>
                    </div>
                    <div className="c-gallery-content">
                        <p className="c-gallery-content__text">A selection of growth experiments<span></span></p>
                    </div>
                </Link>
                <Link to="/tw/" className="c-gallery-item c-gallery-item--2 u-bgcolor-neutral-light">
                    <Img fluid={data.img_cover_tw.childImageSharp.fluid} alt="Thames Water" className="c-gallery-item__img" />
                    <div className="c-gallery-meta">
                        <p className="c-gallery-meta__text"><span className="c-avatar"></span>Thames Water</p>
                        <ul className="o-list o-list--horizontal c-tags">
                            <li className="o-list__item c-tag">IA</li>
                            <li className="o-list__item c-tag">Prototyping</li>
                            <li className="o-list__item c-tag">Visual design</li>
                            <li className="o-list__item c-tag">Design system</li>
                        </ul>
                    </div> 
                    <div className="c-gallery-content">
                        <p className="c-gallery-content__text">Designing a solution to report a problem <span></span></p>
                    </div>
                </Link>
                <Link to="/cobbled-climbs/" className="c-gallery-item c-gallery-item--3 u-bgcolor-neutral-light">
                    <Img fluid={data.img_cover_cc.childImageSharp.fluid} alt="Cobbled climbs" className="c-gallery-item__img" />
                    <div className="c-gallery-meta">
                        <p className="c-gallery-meta__text"><span className="c-avatar"></span>Cobbled Climbs</p>
                        <ul className="o-list o-list--horizontal c-tags">
                            <li className="o-list__item c-tag">Visual design</li>
                            <li className="o-list__item c-tag">HTML/CSS/JS</li>
                            <li className="o-list__item c-tag">React</li>
                        </ul>
                    </div>
                    <div className="c-gallery-content">
                        <p className="c-gallery-content__text">A personal project to explore React/Redux, SVG graphs and theming <span></span></p>
                    </div>
                </Link>
                {/*
                <Link to="/echelon/" className="c-gallery-item c-gallery-item--4 u-bgcolor-neutral-light">
                    <div className="c-gallery-content">
                        <p className="c-gallery-content__text">A design challenge to create awareness for safer cycling routes <span></span></p>
                    </div> 
                    <Img fluid={data.img_cover_scr.childImageSharp.fluid} alt="Safer cycling routes" className="c-gallery-item__img" />
                    <div className="c-gallery-meta">
                        <p className="c-gallery-meta__text"><span className="c-avatar"></span>Safer cycling routes</p>
                        <ul className="o-list o-list--horizontal c-tags">
                            <li className="o-list__item c-tag">Research</li>
                            <li className="o-list__item c-tag">Prototyping</li>
                            <li className="o-list__item c-tag">Visual design</li>
                        </ul>
                    </div> 
                </Link>
                */}
                <Link to="/sp/" className="c-gallery-item c-gallery-item--4 u-bgcolor-neutral-light">
                    <Img fluid={data.img_cover_sp.childImageSharp.fluid} alt="S&P Global" className="c-gallery-item__img" />
                    <div className="c-gallery-meta">
                        <p className="c-gallery-meta__text"><span className="c-avatar"></span>S&P Global</p>
                        <ul className="o-list o-list--horizontal c-tags">
                            <li className="o-list__item c-tag">IA</li>
                            <li className="o-list__item c-tag">Prototyping</li>
                            <li className="o-list__item c-tag">Visual design</li>
                            <li className="o-list__item c-tag">Design system</li>
                        </ul>
                    </div>
                    <div className="c-gallery-content">
                        <p className="c-gallery-content__text">Building a marketplace that provides real-time datasets <span></span></p>
                    </div> 
                </Link>
                <Link to="/echelon/" className="c-gallery-item c-gallery-item--5 u-bgcolor-neutral-light">
                    <Img fluid={data.img_cover_echelon.childImageSharp.fluid} alt="Echelon" className="c-gallery-item__img" />
                    <div className="c-gallery-meta">
                        <p className="c-gallery-meta__text"><span className="c-avatar"></span>Echelon</p>
                        <ul className="o-list o-list--horizontal c-tags">
                            <li className="o-list__item c-tag">Visual design</li>
                            <li className="o-list__item c-tag">Design system</li>
                        </ul>
                    </div>
                    <div className="c-gallery-content">
                        <p className="c-gallery-content__text">Echelon is a boilerplate UI kit for user interfaces <span></span></p>
                    </div> 
                </Link>
                <Link to="/bene/" className="c-gallery-item c-gallery-item--6 u-bgcolor-neutral-light">
                    <Img fluid={data.img_cover_bene.childImageSharp.fluid} alt="Echelon" className="c-gallery-item__img" />
                    <div className="c-gallery-meta">
                        <p className="c-gallery-meta__text"><span className="c-avatar"></span>Benevolent.ai</p>
                        <ul className="o-list o-list--horizontal c-tags">
                            <li className="o-list__item c-tag">Identity design</li>
                        </ul>
                    </div>
                    <div className="c-gallery-content">
                        <p className="c-gallery-content__text">Artificial intelligence and deep learning software for scientific purposes.<span></span></p>
                    </div> 
                </Link>
                
                {/*<Link to="/matexi/" className="c-gallery-item c-gallery-item--7 u-bgcolor-matexi-base js-toolbox">
                    <Img fluid={data.img_matexi_teaser.childImageSharp.fluid} alt="Matexi teaser" className="c-gallery-item__img" />
                    <h2 className="c-gallery-item__caption js-tooltip">Matexi</h2>
                    <ul className="o-list o-list--horizontal c-tags">
                        <li className="o-list__item c-tag">UI design</li>
                        <li className="o-list__item c-tag">HTML/CSS/JS</li>
                    </ul>
                </Link>
                <Link to="/maunga/" className="c-gallery-item c-gallery-item--8 u-bgcolor-maunga-light js-toolbox">
                    <Img fluid={data.img_maunga_teaser.childImageSharp.fluid} alt="Maunga teaser" className="c-gallery-item__img" />
                    <h2 className="c-gallery-item__caption js-tooltip">Maunga</h2>
                    <ul className="o-list o-list--horizontal c-tags">
                        <li className="o-list__item c-tag">Identity design</li>
                        <li className="o-list__item c-tag">UI design</li>
                    </ul>
                </Link>
                <Link to="/werchter/" className="c-gallery-item c-gallery-item--8 u-bgcolor-werchter-base js-toolbox">
                    <Img fluid={data.img_werchter_teaser.childImageSharp.fluid} alt="Rock werchter teaser" className="c-gallery-item__img" />
                    <h2 className="c-gallery-item__caption js-tooltip">Rock Werchter</h2>
                    <ul className="o-list o-list--horizontal c-tags">
                        <li className="o-list__item c-tag">UI design</li>
                        <li className="o-list__item c-tag">HTML/CSS/JS</li>
                    </ul>
                </Link>*/}
            </section>
            <section className="c-row c-row--beta" id="about">
                <div className="f-grid u-grid-full">
                    <div className="f-col-bp1-4 f-col-bp2-10 f-col-bp3-7 u-ver-center">
                        <div className="c-tile__content">
                            <h2>I am T-shaped.</h2>
                            <p>I’ve been fortunate to explore many facets of design over the past decade. It has shaped me into a versatile designer with an affinity for visual design, but with a generalist mindset.</p>
                            <p>I've been a digital product designer at <a href="https://www.askattest.com/" className="c-link">Attest</a>, <a href="http://www.hugoandcat.com/" className="c-link">Hugo &amp; Cat</a> and Wijs (now <a href="https://www.springbokagency.com/" className="c-link">Springbok</a>). I care about working efficiently with like-minded people coming up with simple and effective solutions.</p>
                            <p className="u-mb-zeta">I'm currently based in London, working fully remote. Feel free to get in touch.</p>
                            <ul className="o-list o-list--horizontal c-social__list" id="contact">
                                {/*<li className="o-list__item c-social__list-item">
                                    <a href="http://twitter.com/driesstandaert" target="_blank" rel="noreferrer" className="c-social__link c-social__link--twitter">Twitter</a>
                                </li>*/}
                                <li className="o-list__item c-social__list-item">
                                    <a href="https://www.linkedin.com/in/dries-standaert-67635383/" target="_blank" title="LinkedIn" rel="noreferrer" className="c-social__link c-social__link--linkedin">LinkedIn</a>
                                </li>
                                {/*<li className="o-list__item c-social__list-item">
                                    <a href="https://dribbble.com/driesstandaert" target="_blank" rel="noreferrer" className="c-social__link c-social__link--dribbble">dribbble</a>
                                </li>*/}
                                <li className="o-list__item c-social__list-item u-mr-beta">
                                    <a href="http://codepen.io/driesstandaert/" target="_blank" title="Codepen" rel="noreferrer" className="c-social__link c-social__link--codepen">Codepen</a>
                                </li>
                                <li className="o-list__item c-social__list-item u-pl-beta">
                                    <a href="mailto:dries.standaert@gmail.com" title="dries.standaert@gmail.com" className="c-link">Email</a>
                                </li>
                                {/*<li className="o-list__item c-social__list-item">
                                    <a href="https://github.com/driesstandaert" target="_blank" rel="noreferrer" className="c-social__link c-social__link--github">Github</a>
                                </li>*/}
                            </ul>
                        </div>
                    </div>
                    {/*<div className="f-col-bp1-4 f-col-bp2-12 f-col-bp3-6">
                        <div>
                            <figure className="u-ratio u-ratio-1x1">
                                <Img fluid={data.img_tshape.childImageSharp.fluid} alt="Ulisse Aldrovandi - a man with six arms"/>
                            </figure>
                        </div>
                    </div>*/}
                </div>
            </section>
            <section className="c-row c-row--beta" id="about">
                <div className="f-grid u-grid-full">
                    <div className="f-col-bp1-4 u-ver-center">
                        <div className="c-tile__content">
                            <p>More projects?
                                &nbsp;&nbsp;<a href="/atlascopco" title="Atlas copco" rel="noreferrer" className="c-link">Atlas copco</a>&nbsp; - &nbsp;
                                <a href="/matexi" title="Atlas copco" rel="noreferrer" className="c-link">Matexi</a>&nbsp;  - &nbsp;
                                <a href="/werchter" title="Atlas copco" rel="noreferrer" className="c-link">Werchter</a>&nbsp; - &nbsp; <a href="/ferret" title="Atlas copco" rel="noreferrer" className="c-link">Ferret.io</a>
                            </p>                    
                        </div>
                    </div>
                </div>
            </section>
        </LayoutHome>
    )
};

export default IndexPage;